// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Main color date picker single */
.MuiPickersDay-current {
  color: #5770c6 !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #5770c6 !important;
}
.MuiPickersDay-daySelected {
  background-color: #5770c6 !important;
  color: #fff !important;
}

/* date picker range */
.DatePickerRange-day {
  margin: 0 !important;
  width: 40 !important;
  border-radius: 0 !important;
}

.DatePickerRange-day-beginCap {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.DatePickerRange-day-endCap {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.MuiOutlinedInput-input {
  padding: 18.5px 20px !important;
}
.MuiIconButton-root{
  width: 32px;
  height: 32px;
  padding: 9px!important;
}

.table-row-kols-padding{
  padding: 14px 8px 15px 0!important;
}
.table-header-kols-padding{
  padding: 20px 8px 15px 0px!important;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

`, "",{"version":3,"sources":["webpack://./src/style/style.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;EACE,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;EACpC,sBAAsB;AACxB;;AAEA,sBAAsB;AACtB;EACE,oBAAoB;EACpB,oBAAoB;EACpB,2BAA2B;AAC7B;;AAEA;EACE,sCAAsC;EACtC,yCAAyC;AAC3C;;AAEA;EACE,uCAAuC;EACvC,0CAA0C;AAC5C;AACA;EACE,+BAA+B;AACjC;AACA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,kCAAkC;AACpC;AACA;EACE,oCAAoC;AACtC;;AAEA;EACE,oBAAoB;EACpB,qBAAqB;EACrB,4BAA4B;EAC5B,gBAAgB;AAClB","sourcesContent":["/* Main color date picker single */\n.MuiPickersDay-current {\n  color: #5770c6 !important;\n}\n\n.MuiPickersToolbar-toolbar {\n  background-color: #5770c6 !important;\n}\n.MuiPickersDay-daySelected {\n  background-color: #5770c6 !important;\n  color: #fff !important;\n}\n\n/* date picker range */\n.DatePickerRange-day {\n  margin: 0 !important;\n  width: 40 !important;\n  border-radius: 0 !important;\n}\n\n.DatePickerRange-day-beginCap {\n  border-top-left-radius: 50% !important;\n  border-bottom-left-radius: 50% !important;\n}\n\n.DatePickerRange-day-endCap {\n  border-top-right-radius: 50% !important;\n  border-bottom-right-radius: 50% !important;\n}\n.MuiOutlinedInput-input {\n  padding: 18.5px 20px !important;\n}\n.MuiIconButton-root{\n  width: 32px;\n  height: 32px;\n  padding: 9px!important;\n}\n\n.table-row-kols-padding{\n  padding: 14px 8px 15px 0!important;\n}\n.table-header-kols-padding{\n  padding: 20px 8px 15px 0px!important;\n}\n\n.line-clamp-3 {\n  display: -webkit-box;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;  \n  overflow: hidden;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
