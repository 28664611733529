import { memo, useState } from 'react';
import styled from 'styled-components';

import { formatCurrencyInput } from '../../../utils/formatCurrency';
import { generatePath, Link } from 'react-router-dom';

import { ReactComponent as CloseSVG } from '../../../assets/svg/close_black_24dp.svg';

import PopUpLayout from '../popUpLayout/PopUpLayout';
import Button from '../../commons/Button';
import { ROUTES } from '../../../utils/constants';

const ConfirmPopUp = ({ handlePublish, onClose, requireAmount, requireAmountUSD, currency, isLoadingPublish, confirmTitle }) => {
  const publishCampaignTermsPath = generatePath(ROUTES.publishCampaignTerms);
  console.log('publishCampaignTermsPath', publishCampaignTermsPath);
  const [agreeChecked, setAgreeChecked] = useState(false);

  const validate = () => {
    if (!agreeChecked) {
      alert('You must agree before proceeding.');
      return;
    }
    // Else, proceed with the button click action...
    handlePublish();
  };

  return (
    <PopUpLayout width={`${window.innerWidth / 3}px`}>
      {' '}
      <Header>
        <HeaderTitle>Campaign Publishing</HeaderTitle>
        <CloseSVG onClick={onClose} />
      </Header>
      <Content>
        <p>In order to publish a campaign, we require your agreement to our terms and conditions.</p>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="checkbox"
            id="agree"
            name="agree"
            onChange={(e) => setAgreeChecked(e.target.checked)}
            checked={agreeChecked}
          />
          <label htmlFor="agree" style={{ padding: '0 5px' }}>
            I have read and agreed to the
            <Link to={publishCampaignTermsPath} target="_blank" rel="noopener noreferrer" style={{ padding: '0 5px' }}>
              general terms and conditions
            </Link>
          </label>
        </div>
        <ButtonStyled label={isLoadingPublish ? 'WAITING' : 'PUBLISH'} onClick={validate} />
      </Content>
    </PopUpLayout>
  );
};

export default memo(ConfirmPopUp);

const Header = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 41px 20px 20px;
  border-bottom: 1px solid #eeeeee;

  svg {
    cursor: pointer;
  }
`;

const HeaderTitle = styled.div<any>`
  font: normal normal bold 34px/41px Helvetica Neue;
  color: #000000;
`;

const Content = styled.div<any>`
  width: 100%;
  max-width: initial;
  padding: 0px 20px 20px;
`;

const MoneyStyled = styled.div<any>`
  font: normal normal bold 26px/32px Helvetica Neue;
  color: #000000;
`;

const MoneyStyledSecondary = styled.div<any>`
  font: normal normal bold 16px/20px Helvetica Neue;
  color: #717171;
`;

const DescribeStyled = styled.div<any>`
  font: normal normal bold 16px/19px Helvetica Neue;
  color: #5770c6;
  margin: 8px 0 40px;
`;

const ButtonStyled = styled<any>(Button)`
  height: 46px;
  margin-top: 20px;
`;
