// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
  }
  
  .button {
    width: 100px;
    height: 50px;
    margin-left: 10px;
  }
  .discard {
    background: none;
    border: none;
    color: #717171;
    font-weight: bold;
  }
  .save {
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    background: linear-gradient(90deg, #B54EC9 -8.82%, #7D62EC 31.18%, #628FEE 69.12%, #57AEF1 98.72%);
  }`, "",{"version":3,"sources":["webpack://./src/components/campaigns/ContentMetrics/ActionButtons/ActionButtons.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,iBAAiB;EACnB;EACA;IACE,gBAAgB;IAChB,YAAY;IACZ,cAAc;IACd,iBAAiB;EACnB;EACA;IACE,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,kGAAkG;EACpG","sourcesContent":[".button-container {\n    display: flex;\n    justify-content: flex-end;\n    margin-bottom: 15px;\n  }\n  \n  .button {\n    width: 100px;\n    height: 50px;\n    margin-left: 10px;\n  }\n  .discard {\n    background: none;\n    border: none;\n    color: #717171;\n    font-weight: bold;\n  }\n  .save {\n    color: white;\n    font-weight: bold;\n    border: none;\n    border-radius: 5px;\n    background: linear-gradient(90deg, #B54EC9 -8.82%, #7D62EC 31.18%, #628FEE 69.12%, #57AEF1 98.72%);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
