import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import './style/style.css';
import './style/main.css';
import {
  REACT_APP_APOLLO_GRAPHQL_URL,
  REACT_APP_APOLLO_GRAPHQL_WEBSOCKET_URL,
  REACT_APP_AUTH0_HOST,
  REACT_APP_AUTH0_MERCHANT_CLIENT_ID,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENVIRONMENT,
  REACT_APP_STRIPE_PUBLIC_KEY,
  REACT_APP_WEBHOOKS_BASE_URL,
} from './configs';

console.log({
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENVIRONMENT,
  REACT_APP_STRIPE_PUBLIC_KEY,
  REACT_APP_APOLLO_GRAPHQL_WEBSOCKET_URL,
  REACT_APP_APOLLO_GRAPHQL_URL,
  REACT_APP_AUTH0_HOST,
  REACT_APP_AUTH0_MERCHANT_CLIENT_ID,
  REACT_APP_WEBHOOKS_BASE_URL,
});

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: REACT_APP_SENTRY_ENVIRONMENT,
});

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>
    <App />
    {/* <React.StrictMode>
    </React.StrictMode> */}
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);
