import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useForm, useFieldArray, FormProvider, Controller } from 'react-hook-form';
import Collapsible from 'react-collapsible';
import { format } from 'date-fns';

import OriginalTextField from '../../../commons/TextField';
import Button from '../../../commons/Button';
import OriginalTextFieldArea from '../../../commons/TextFieldArea';
import OriginalTextFieldTag from '../../../commons/TextFieldTag';
import OriginalSelectbox from '../../../commons/Selectbox';
import OriginalCheckboxList from '../../../commons/CheckBoxList';
import OriginalRadioInput from '../../../commons/RadioInput';
import OriginalTextFieldCurrency from '../../../commons/TextFieldCurrency';
import OriginalTextFieldNumber from '../../../commons/TextFieldNumber';
import OriginalDatepicker from '../../../commons/Datepicker';
import Modal from '../../../commons/Modal';
import ErrorMessageStyled from '../../../commons/ErrorMessage';
import ContainerScroll from '../../../commons/ContainerScroll';

import RowStyled from '../../../styled/RowStyled';
import { ReactComponent as ErrorSVG } from '../../../../assets/svg/error_black_24dp.svg';
import { ReactComponent as MinusSVG } from '../../../../assets/svg/remove_black_24dp.svg';
import { ReactComponent as PlusSVG } from '../../../../assets/svg/add_black_24dp.svg';
import { ReactComponent as AddSVG } from '../../../../assets/svg/control_point_black_24dp.svg';
import { ReactComponent as ArrowDownSVG } from '../../../../assets/svg/drop_down_bottom.svg';
import { ReactComponent as PenSVG } from '../../../../assets/svg/drive_file_rename_outline_black_24dp.svg';

import useMobile from '../../../../hooks/useMobile';
import KolRequirementMobileHeader from './KolRequirementMobileHeader';
import { mobileQueryString } from '../../../../utils/responsive';
import DeliverablePopup from './DeliverablePopup';
import DeliverablePopupContent from './DeliverablePopup/DeliverablePopupConent/DeliverablePopupContent';
import useConvertCurrency from '../../../../hooks/useConvertCurrency';
import { formatCurrencyInput } from '../../../../utils/formatCurrency';

import Select from 'react-select';

const DEFAULT_COMMA_CURRENCY = ',';
const TIME_FORMAT = 'yyyy/MM/dd';

const formatCurrency = (value, type) => {
  if (!value) return '';
  if (typeof value === 'number') {
    return value;
  }
  let newValue;

  if (type === 'num') {
    newValue = parseInt(value.split(DEFAULT_COMMA_CURRENCY).join(''));
  } else {
    newValue = parseInt(value.split('').join(','));
  }
  return !!newValue ? newValue : 0;
};
function KolsRequirement(props) {
  const {
    profileData = {},
    submitRequirement,
    formData = [],
    listCategory = {},
    onCancelKols,
    isLoading,
    btnSubmitRef,
    handleSaveDraft,
    backToStep,
  } = props;
  const { currency_category: listCurrency = [], country_category: listCountry = [], language: listLanguage = [] } = listCategory;
  const isMobile = useMobile();
  const [group, setGroup] = useState<any>([]);
  const [groupSelected, setGroupSelected] = useState<any>(false);
  const valRefs = useRef<any>([]);
  const errorRefs = useRef<any>([]);
  const elRefs = useRef<any>([]);
  const [isShowMessage, setIsShowMessage] = useState<any>(false);
  const tempRef = useRef<any>([]);
  const { exchangeRate } = useConvertCurrency();

  const startDate = props.startDate;
  const endDate = props.endDate;

  const handleChangeGroup = (index) => (value) => {
    valRefs.current[index] = {
      name: group?.[index]?.name,
      value,
    };
  };

  const handlePIKDescription = (index) => (value) => {
    tempRef.current[index].value.product_description = value;
  };

  const handleReq = (index) => (value) => {
    tempRef.current[index].value.requirements = value;
  };

  const handleUpdate = (index) => (value) => {
    tempRef.current[index] = {
      name: group?.[index]?.name,
      value,
    };
  };

  useEffect(() => {
    setGroup([
      {
        name: 'New group',
      },
    ]);
  }, []);

  const handleErrorGroup = (index) => (error) => {
    errorRefs.current[index] = error;
  };

  const handleCreateNewGroup = () => {
    setGroup((prev) => [...prev, { name: 'New group' }]);
  };

  const handleOpenRenameDialog = (index) => (e) => {
    e.stopPropagation();
    setGroupSelected(group[index]);
  };

  const handleRemoveGroup = (index) => (e) => {
    e.stopPropagation();
    const newArray = [...group.slice(0, index), ...group.slice(index + 1)];
    const newValRefs = [...valRefs.current.slice(0, index), ...valRefs.current.slice(index + 1)];
    setGroup(newArray);
    valRefs.current = newValRefs;
  };

  const handleRename = (value) => {
    groupSelected.name = value;
    setGroup((prev) => [...prev]);
    setTimeout(() => {
      setGroupSelected(false);
    }, 100);
  };

  const mappingDataAPi = (value) => {
    return {
      data: value
        .filter((v) => {
          if (typeof v === 'string') {
            return v;
          }
          return !!v?.checked;
        })
        .map((v) => {
          if (typeof v === 'string') {
            return {
              category_code: v,
            };
          }
          return {
            category_code: v.value,
          };
        }),
    };
  };

  const mappingGenderApi = (value) => {
    if (value === 'BOTH') {
      return {
        data: [{ category_code: 'MALE' }, { category_code: 'FEMALE' }],
      };
    }
    return {
      data: [{ category_code: value }],
    };
  };

  const mappingTracheLanguageApi = (list) => {
    console.log('list: ', list);

    const normalizedList = Array.isArray(list) ? list : [...list.data];

    console.log('normalizedList: ', normalizedList);

    return {
      data: normalizedList.map((lang) => ({
        language_code: lang.value || lang.language_code,
        value: lang.value,
        label: lang.label,
      })),
    };
  };

  const handleCancel = () => {
    const convertData = tempRef.current.map((form) => {
      const { name, value } = form;
      const {
        cash_usd_cents,
        product_value_usd_cents,
        product_categories = [],
        social_platforms = [],
        age_groups = [],
        deliverable_specs = [],
        genders = [],
        mapping_tranche_languages,
      } = value;

      console.log('handleCancel: ', value);
      const tranche_languages = mappingTracheLanguageApi(mapping_tranche_languages);
      console.log('tranche_languages: ', tranche_languages);

      return {
        name,
        ...value,
        cash_usd_cents: (formatCurrency(cash_usd_cents, 'num') * 100) / exchangeRate,
        product_value_usd_cents: (formatCurrency(product_value_usd_cents, 'num') * 100) / exchangeRate,
        product_categories: mappingDataAPi(product_categories),
        social_platforms: mappingDataAPi(social_platforms),
        genders: mappingDataAPi(genders),
        age_groups: mappingDataAPi(age_groups),
        deliverable_specs: {
          data: deliverable_specs.map((d) => ({ ...d, due_date: format(new Date(d.due_date), TIME_FORMAT) })),
        },
        mapping_tranche_languages: tranche_languages,
      };
    });

    onCancelKols(convertData);
  };

  const handlePreview = () => {
    handleSaveDraft(false);
    setIsShowMessage(group.length === 0);
    if (isLoading || group.length === 0) return;
    if (elRefs.current.length) {
      for (let i = 0; i < elRefs.current.length; i++) {
        const ref = elRefs.current[i];
        ref.current.click();
      }
    }
    setTimeout(() => {
      if (errorRefs.current.length) {
        for (let i = 0; i < errorRefs.current.length; i++) {
          const ref = errorRefs.current[i];
          if (Object.keys(ref).length > 0) {
            return;
          }
        }
      }
      const convertData = valRefs.current.map((form) => {
        const { name, value } = form;
        const {
          cash_usd_cents,
          product_value_usd_cents,
          product_categories = [],
          social_platforms = [],
          age_groups = [],
          deliverable_specs = [],
          genders = [],
          mapping_tranche_languages,
        } = value;

        console.log('handlePreview: ', value);
        const tranche_languages = mappingTracheLanguageApi(mapping_tranche_languages);
        console.log('tranche_languages: ', tranche_languages);

        return {
          name,
          ...value,
          cash_usd_cents: (formatCurrency(cash_usd_cents, 'num') * 100) / exchangeRate,
          product_value_usd_cents: (formatCurrency(product_value_usd_cents, 'num') * 100) / exchangeRate,
          product_categories: mappingDataAPi(product_categories),
          social_platforms: mappingDataAPi(social_platforms),
          genders: mappingDataAPi(genders),
          age_groups: mappingDataAPi(age_groups),
          deliverable_specs: {
            data: deliverable_specs.map((d) => ({ ...d, due_date: format(new Date(d.due_date), TIME_FORMAT) })),
          },
          mapping_tranche_languages: tranche_languages,
        };
      });

      submitRequirement(convertData);
    }, 0);
  };

  if (elRefs.current.length !== group.length) {
    // add or remove refs
    elRefs.current = Array(group.length)
      .fill(null)
      .map((_, i) => elRefs.current[i] || React.createRef());
  }

  useEffect(() => {
    if (Array.isArray(formData) && formData.length > 0) {
      setGroup(formData);
    }
  }, [formData, formData.length]);

  return (
    <WrapperStyled>
      {isMobile && <KolRequirementMobileHeader onGoBack={backToStep} onPreview={handlePreview} />}
      <GroupHeaderStyled>
        <RowStyled>
          <CampaignTitle>Enter Kols Requirements</CampaignTitle>
        </RowStyled>
        <RowStyled style={{ width: 'fit-content' }}>
          <Button type="button" width="110px" variant="secondary" label="Cancel" onClick={handleCancel} />
          <Button width="149px" label={isLoading ? 'WAITING' : 'PREVIEW'} onClick={handlePreview} />
          <HideButtonStyled
            ref={btnSubmitRef}
            onClick={() => {
              handlePreview();
            }}
            type="button"
          ></HideButtonStyled>
        </RowStyled>
      </GroupHeaderStyled>
      <ContainerScroll>
        {group.map((data, idx) => (
          <CollapsibleGroup
            open
            trigger={
              <CollapsibleTitle className="Collapsible__title">
                <span className="Collapsible__name">{data?.name}</span>
                <div className="Collapsible__right_action">
                  <span className="Collapsible__rename" onClick={handleOpenRenameDialog(idx)}>
                    <OpenIconStyled fill="#5770C6" width="15px" height="15px" />
                    Rename
                  </span>
                  <span className="Collapsible__remove" onClick={handleRemoveGroup(idx)}>
                    Remove
                  </span>
                  <ArrowDownSVG className="Collapsible__arrow" />
                </div>
              </CollapsibleTitle>
            }
            key={idx}
          >
            <KolsRequirementGroup
              ref={elRefs.current[idx]}
              profileData={profileData}
              listCountry={listCountry}
              listLanguage={listLanguage}
              listCurrency={listCurrency}
              onChange={handleChangeGroup(idx)}
              onUpdate={handleUpdate(idx)}
              onError={handleErrorGroup(idx)}
              onChangePIKDescription={handlePIKDescription(idx)}
              onChangeReq={handleReq(idx)}
              data={data}
              listCategory={listCategory}
              name={data.name}
              startDate={startDate}
              endDate={endDate}
            />
          </CollapsibleGroup>
        ))}
        <AddMoreGroup>
          <span onClick={handleCreateNewGroup}>Create New KOL Group</span>
          <ActionStyled>
            <AddIcon fill="#5770C6" width="17px" height="17px" onClick={handleCreateNewGroup} />
          </ActionStyled>
        </AddMoreGroup>
        <ChangeGroupNameModal
          isOpen={!!groupSelected}
          value={groupSelected?.name || ''}
          closeModal={() => setGroupSelected(false)}
          onRename={handleRename}
        />
        {group.length === 0 && isShowMessage && <CustomErrorMessageStyled>Group is require</CustomErrorMessageStyled>}
      </ContainerScroll>
    </WrapperStyled>
  );
}

const KolsRequirementGroup = React.forwardRef<any, any>((props, ref) => {
  const {
    profileData = {},
    listCountry = [],
    listCurrency = [],
    onChange,
    onUpdate,
    onError,
    onChangePIKDescription,
    onChangeReq,
    data = {},
    listCategory = [],
    listLanguage = [],
    name,
  } = props;
  const { exchangeRate } = useConvertCurrency();
  const methods = useForm({
    defaultValues: {
      product_categories: [],
      deliverable_specs: [],
      social_platforms: [],
      max_follower_size: '',
      min_follower_size: '',
      age_groups: [],
      number_kols: '',
      id: '',
      cash_usd_cents: '',
      product_value_usd_cents: '',
      product_description: '',
      requirements: '',
      genders: [],
      country_code: '',
      mapping_tranche_languages: [],
    },
  });

  const { register, handleSubmit, formState, setValue, getValues, trigger, control, watch } = methods;
  const isMobile = useMobile();
  const { errors } = formState;
  const [currencySelected, setCurrencySelected] = useState<any>();
  const [isShowDeliverable, setIsShowDeliverable] = useState(false);
  const dynamicFormMethods = useFieldArray({
    control,
    name: 'deliverable_specs',
  } as any);
  const { fields: deliverablesFields, append: deliverablesAppend, remove: deliverablesRemove } = dynamicFormMethods;
  const watchMaxFollow = watch('max_follower_size');
  const watchMinFollow = watch('min_follower_size');
  const [languageDisplay, setLanguageDisplay] = useState<any>();
  const [cashDisplay, setCashDisplay] = useState<any>();
  const [pikDisplay, setPikDisplay] = useState<any>();

  useEffect(() => {
    if (!!watchMaxFollow) trigger('min_follower_size');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchMaxFollow, watchMinFollow]);

  const GenderOptions = useMemo(() => {
    if (!Array.isArray(listCategory?.gender_category)) return [];
    const listGenderCode = data?.genders?.data || data?.genders || [];
    const reusult = [
      ...listCategory?.gender_category.map((gender) => ({
        ...gender,
        checked: !!listGenderCode.find((a) => a.category_code === gender.code),
        value: gender.code,
      })),
    ];
    return reusult;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.genders, listCategory?.gender_category]);

  const AgeGroupOptions = useMemo(() => {
    if (!Array.isArray(listCategory?.age_category)) return [];
    const listAgeCode = data?.age_groups?.data || data?.age_groups || [];
    return [
      ...listCategory?.age_category.map((age) => ({
        ...age,
        checked: !!listAgeCode.find((a) => a.category_code === age.code),
        value: age.code,
      })),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.age_groups, listCategory?.age_category]);

  const SocialGroupOptions = useMemo(() => {
    if (!Array.isArray(listCategory?.social_platform_category)) return [];
    const listSocial = data?.social_platforms?.data || data?.social_platforms || [];
    return [
      ...listCategory?.social_platform_category.map((p) => ({
        ...p,
        checked: !!listSocial.find((s) => s.category_code === p.code),
        value: p.code,
      })),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.social_platforms, listCategory?.social_platform_category]);

  const ProductGroupOptions = useMemo(() => {
    if (!Array.isArray(listCategory?.product_category)) return [];
    const listProductCategory = data?.product_categories?.data || data?.product_categories || [];
    return [
      ...listCategory?.product_category.map((p) => ({
        ...p,
        checked: !!listProductCategory.find((lp) => lp.category_code === p.code),
        value: p.code,
      })),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.product_categories, listCategory?.product_category]);

  const resetDefault = useCallback(() => {
    setTimeout(() => {
      const code = profileData?.default_currency?.code;
      let findCurrency;
      if (code) {
        findCurrency = listCurrency.find((c) => c.code === code);
        setCurrencySelected(findCurrency);
      }
      setValue('product_categories', ProductGroupOptions as any);
      setValue('min_follower_size', data.min_follower_size);
      setValue('max_follower_size', data.max_follower_size);
      setValue('social_platforms', SocialGroupOptions as any);
      setValue('age_groups', AgeGroupOptions as any);
      setValue('number_kols', data.number_kols || '');
      setValue('genders', GenderOptions as any);

      console.log('resetDefault', data.mapping_tranche_languages);

      if (data?.mapping_tranche_languages?.length) {
        const listLanguages = listLanguage
          .map((c) => ({ value: c.code, label: c.title }))
          .filter(
            (item) =>
              data.mapping_tranche_languages &&
              data?.mapping_tranche_languages?.map((item) => item.language_code).includes(item.value),
          );
        setLanguageDisplay(listLanguages);
      }

      setValue('mapping_tranche_languages', data.mapping_tranche_languages || []);

      if (data?.cash_usd_cents) {
        setValue(
          'cash_usd_cents',
          formatCurrencyInput((data.cash_usd_cents / 100) * exchangeRate, profileData?.default_currency?.fraction_size) || '',
        );
      }

      if (data?.cash_usd_cents) {
        setCashDisplay((data.cash_usd_cents / 100) * exchangeRate || '');
      }

      setValue('country_code', data?.country_code);

      if (data?.product_value_usd_cents) {
        setPikDisplay((data.product_value_usd_cents / 100) * exchangeRate);
      }
      if (data?.product_value_usd_cents) {
        setValue('product_value_usd_cents', formatCurrencyInput((data.product_value_usd_cents / 100) * exchangeRate));
      }

      setValue('product_description', data.product_description || '');
      setValue('requirements', data.requirements || '');
    }, 0);
    // eslint-disable-next-line
  }, [
    AgeGroupOptions,
    ProductGroupOptions,
    SocialGroupOptions,
    GenderOptions,
    data.cash_usd_cents,
    data?.genders,
    data.max_follower_size,
    data.min_follower_size,
    data.number_kols,
    data.product_description,
    data.product_value_usd_cents,
    data.requirements,
    exchangeRate,
    listCurrency,
    profileData?.default_currency?.code,
    profileData?.default_currency?.fraction_size,
    setValue,
  ]);

  const [isEmptyDeliverable, setIsEmptyDeliverable] = useState(false);
  const startDate = props.startDate;
  const endDate = props.endDate;

  useEffect((): any => {
    const isCategoryEmpty = !Array.isArray(listCategory?.content_type) || listCategory?.content_type?.length === 0;
    const listDeliverable = data?.deliverable_specs?.data || data?.deliverable_specs;
    if (isCategoryEmpty) return [];
    setValue(
      'deliverable_specs',
      Array.isArray(listDeliverable)
        ? listDeliverable.map((item) => {
            const itemDueDate = item.due_date;
            if (itemDueDate < startDate) {
              return { ...item, due_date: endDate };
            }
            return item;
          })
        : ([
            {
              requirement_description: '',
              quantity: 1,
              due_date: endDate,
              content_type_code: listCategory.content_type[0].code,
              title: '',
            },
          ] as any),
    );
    setValue('id', data?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.deliverable_specs, listCategory?.content_type, endDate]);

  useEffect(() => {
    onError(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    resetDefault();
  }, [listCategory, data, resetDefault]);

  const typeOptions = useMemo(() => {
    if (!Array.isArray(listCategory?.content_type)) return [];
    return listCategory.content_type.map((type) => ({
      ...type,
      value: type.code,
    }));
  }, [listCategory?.content_type]);

  const handleAddMoreDeliverables = () => {
    deliverablesAppend({
      requirement_description: '',
      quantity: 1,
      due_date: endDate,
      content_type_code: listCategory?.content_type[0]?.code || 'ig_photo',
      title: '',
    });
  };

  const onSubmit = (value) => {
    onChange(value);
  };

  const handleDeleteDeliverable = (index) => {
    if (deliverablesFields.length <= 1) {
      setIsEmptyDeliverable(true);
      setTimeout(() => {
        setIsEmptyDeliverable(false);
      }, 5000);
      return;
    }
    deliverablesRemove(index);
  };
  onUpdate(getValues());

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <HideInputId {...register('id')} />
        <GroupItemStyled>
          <FormContainerStyled>
            <TextFieldTag
              {...register('product_categories', {
                validate: (value) => {
                  if (!value || !Array.isArray(value)) return value;
                  const isEmpty =
                    (Array.isArray(value) && value.length === 0) || value.filter((v: any) => v?.checked).length === 0;
                  return isEmpty ? 'Product categories is required' : value;
                },
              })}
              fullWidth
              error={errors?.product_categories?.message}
            />
          </FormContainerStyled>
        </GroupItemStyled>
        <GroupItemStyled col mb="44px">
          <GroupFollowerStyled>
            <TextField
              type="number"
              {...register('min_follower_size', {
                validate: (value): any => {
                  if (!value) return 'Min follow is required';
                  const isInvalid = +value > +watchMaxFollow;
                  return isInvalid ? 'Min follower can be less than Max follower' : null;
                },
              })}
              name="min_follower_size"
              error={errors?.min_follower_size?.message}
              label="Min Followers"
            />
            <TextField
              type="number"
              {...register('max_follower_size', {
                required: {
                  value: true,
                  message: 'Max follow is required',
                },
              })}
              name="max_follower_size"
              label="Max Followers"
              error={errors?.max_follower_size?.message}
            />
          </GroupFollowerStyled>
          <TextDescription>
            If 2 or more platforms are selected, the follower size will refer to the total followers aggregated from the selected
            platforms.
          </TextDescription>
        </GroupItemStyled>
        <GroupItemStyled mb="30px" gap="99px">
          <Container>
            <FlexBox>
              <CheckboxList
                {...register('social_platforms', {
                  validate: (value): any => {
                    if (!value || !Array.isArray(value)) return value;
                    const isEmpty =
                      (Array.isArray(value) && value.length === 0) || value.filter((v: any) => v?.checked).length === 0;
                    return isEmpty ? 'Platform is required' : value;
                  },
                })}
                values={[]}
                label="Platform"
                error={errors?.social_platforms?.message}
                horizontal={isMobile}
                isCircle={false}
                rowsPerCol={isMobile ? 0 : 2}
                name="social_platforms"
              />
              <div style={{ width: '100px' }}></div>
              <CheckboxList
                {...register('genders', {
                  validate: (value): any => {
                    console.log('value_genders: ', value);
                    if (!value || !Array.isArray(value)) return value;
                    const isEmpty =
                      (Array.isArray(value) && value.length === 0) || value.filter((v: any) => v?.checked).length === 0;
                    return isEmpty ? 'Gender is required' : value;
                  },
                })}
                values={[]}
                label="Genders"
                error={errors?.genders?.message}
                horizontal={isMobile}
                isCircle={false}
                name="genders"
              />
              <div style={{ width: '100px' }}></div>

              <CheckboxList
                {...register('age_groups', {
                  validate: (value) => {
                    if (!value || !Array.isArray(value)) return value;
                    const isEmpty =
                      (Array.isArray(value) && value.length === 0) || value.filter((v: any) => v?.checked).length === 0;
                    return isEmpty ? 'Age is required' : value;
                  },
                })}
                name="age_groups"
                values={[]}
                label="Age group"
                horizontal={isMobile}
                rowsPerCol={isMobile ? 0 : 2}
                isCircle={false}
                error={errors?.age_groups?.message}
              />
            </FlexBox>
          </Container>
        </GroupItemStyled>
        <GroupItemStyled mb="23px">
          <Container>
            <TextField
              // name="number_kols"
              {...register('number_kols', {
                required: {
                  value: true,
                  message: 'Number of KOLS is required',
                },
                min: {
                  value: 0,
                  message: 'Number of KOLS greater than 0',
                },
              })}
              type="number"
              label="Number of KOLs"
              error={errors?.number_kols?.message}
              width="335px"
            />
            <Selectbox
              options={listCountry.map((c) => ({ ...c, value: c.code }))}
              {...register('country_code', {
                required: {
                  value: true,
                  message: 'Location is required',
                },
              })}
              defaultValue={profileData?.country?.code || data?.country_code || ''}
              error={errors?.country_code?.message}
              canInput
              label="Location"
            />
            {/* <Selectbox
              options={listLanguage.map((c) => ({ ...c, value: c.code, name: c.title }))}
              {...register('language_code', {
                required: {
                  value: true,
                  message: 'Language is required',
                },
              })}
              // defaultValue={profileData?.country?.code || data?.country_code || ''}
              error={errors?.language_code?.message}
              canInput
              label="Language"
            /> */}
          </Container>
        </GroupItemStyled>
        <Controller
          name="mapping_tranche_languages"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div>
              <TitleStyled mb="5px" color="#2f2f2f">
                Campaign languages
              </TitleStyled>
              <Select
                defaultValue={[]}
                value={languageDisplay}
                isMulti
                name="mapping_tranche_languages"
                options={listLanguage.map((c) => ({ value: c.code, label: c.title }))}
                className="basic-multi-select"
                classNamePrefix="select"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '100%',
                    maxWidth: '700px',
                  }),
                }}
                placeholder="Select languages..."
                onChange={(value) => {
                  onChange(value);
                  console.log('change: ', value);
                  setLanguageDisplay(value);
                }}
              />
            </div>
          )}
        />
        <TitleStyled mb="18px" mt="40px">
          Remuneration per KOL
        </TitleStyled>
        <GroupItemStyled>
          <Container>
            <CashStyled
              label="Cash"
              symbol={currencySelected?.symbol || '$'}
              {...register('cash_usd_cents', {
                required: {
                  value: true,
                  message: 'Cash is required',
                },
              })}
              value={cashDisplay}
              onChange={(e) => {
                setCashDisplay(formatCurrencyInput(e.target.value, profileData?.default_currency?.fraction_size));
              }}
              error={errors?.cash_usd_cents?.message}
            />
            <CashStyled
              label={
                <PikValueLabelStyled>
                  PIK Value <ErrorSVG width="15.7px" height="15.7px" fill="#949494" />
                </PikValueLabelStyled>
              }
              symbol={currencySelected?.symbol || '$'}
              {...register('product_value_usd_cents', {
                required: {
                  value: false,
                  message: 'PIK value is required',
                },
              })}
              value={pikDisplay}
              onChange={(e) => {
                setPikDisplay(formatCurrencyInput(e.target.value));
              }}
              error={errors?.product_value_usd_cents?.message}
            />
          </Container>
        </GroupItemStyled>
        <GroupItemStyled mb="23px">
          <Container>
            <TextFieldArea
              {...register('product_description', {
                required: {
                  value: true,
                  message: 'PIK description is required',
                },
              })}
              onChange={(value) => {
                onChangePIKDescription(value.target.value);
              }}
              placeholder="Write your PIK Description"
              label="PIK Description"
              error={errors?.product_description?.message}
            />
          </Container>
        </GroupItemStyled>
        {!isMobile ? (
          <>
            <TitleStyled mb="25px">Deliverables {'&'} Due Dates</TitleStyled>
            {/* dynamic */}
            <DeliverablesWrapperStyled col mb="0px" wrap="true">
              {deliverablesFields.map((field: any, index) => {
                const fieldName = `deliverable_specs.${index}`;
                const num = index + 1;
                return (
                  <div key={field.id}>
                    <GroupItemStyled gap="17px" mb="0px">
                      <TitleStyled>{num}.</TitleStyled>
                      <GroupItemStyled col width="100%" mb="18px" gap="17.46px">
                        <DeliverableItem>
                          <GroupItemStyled mb="18px" maxWidth="668px">
                            <TextFieldDeliverables
                              {...register(`${fieldName}.quantity` as any, {
                                required: {
                                  value: true,
                                  message: 'Quantity of deliverable is required',
                                },

                                min: {
                                  value: 1,
                                  message: 'Quantity of deliverable greater than 0',
                                },
                              })}
                              label="No of Deliverables"
                              arrowleft={<MinusSVG />}
                              arrowright={<PlusSVG />}
                              defaultValue={field?.quantity}
                              error={
                                Array.isArray(errors?.deliverable_specs)
                                  ? errors?.deliverable_specs[index]?.quantity?.message
                                  : ''
                              }
                            />

                            <SelectType
                              options={typeOptions}
                              {...register(`${fieldName}.content_type_code` as any, {
                                required: {
                                  value: true,
                                  message: 'Content type is required',
                                },
                              })}
                              error={
                                Array.isArray(errors?.deliverable_specs)
                                  ? errors?.deliverable_specs[index]?.content_type_code?.message
                                  : ''
                              }
                              defaultValue={field?.content_type_code}
                              canInput
                              label="Type"
                            />
                            <Datepicker
                              {...register(`${fieldName}.due_date` as any, {
                                required: {
                                  value: true,
                                  message: 'Due date is required',
                                },
                              })}
                              error={
                                Array.isArray(errors?.deliverable_specs)
                                  ? errors?.deliverable_specs[index]?.due_date?.message
                                  : ''
                              }
                              defaultValue={field?.due_date}
                              label="Due"
                              minDate={startDate}
                              maxDate={endDate}
                            />
                          </GroupItemStyled>
                          <ActionStyled>
                            <ActionDelete onClick={() => handleDeleteDeliverable(index)}>Delete</ActionDelete>
                          </ActionStyled>
                        </DeliverableItem>
                        <GroupItemStyled ml="0px" gap="0px" mb="0px" maxWidth="668px">
                          <TextField
                            {...register(`${fieldName}.requirement_description` as any, {
                              required: {
                                value: true,
                                message: 'Description is required',
                              },
                            })}
                            name={`${fieldName}.requirement_description`}
                            error={
                              Array.isArray(errors?.deliverable_specs)
                                ? errors?.deliverable_specs[index]?.requirement_description?.message
                                : ''
                            }
                            defaultValue={field?.requirement_description}
                            label="Description"
                          />
                        </GroupItemStyled>
                      </GroupItemStyled>
                    </GroupItemStyled>
                    {deliverablesFields.length !== index + 1 && <LineStyled />}
                  </div>
                );
              })}
            </DeliverablesWrapperStyled>
            {isEmptyDeliverable && <CustomErrorMessageStyled>Campaign must be at least 1 deliverable</CustomErrorMessageStyled>}
            <LineStyled />
            <AddMoreFieldStyled>
              <span onClick={handleAddMoreDeliverables}>Add Deliverables</span>
              <ActionStyled>
                <AddIcon onClick={handleAddMoreDeliverables} fill="#5770C6" width="17px" height="17px" />
              </ActionStyled>
            </AddMoreFieldStyled>
          </>
        ) : (
          <>
            <DeliverablePopup onClick={() => setIsShowDeliverable(true)} length={deliverablesFields.length} />
            <DeliverablePopupContent
              fields={deliverablesFields}
              append={handleAddMoreDeliverables}
              remove={handleDeleteDeliverable}
              isEmptyDeliverable={isEmptyDeliverable}
              typeOptions={typeOptions}
              isShowDeliverable={isShowDeliverable}
              buttonClick={() => setIsShowDeliverable(false)}
              name={name}
            />
          </>
        )}
        <GroupItemStyled mb="31px">
          <Container>
            <TextFieldArea
              {...register('requirements')}
              onChange={(value) => {
                onChangeReq(value.target.value);
              }}
              placeholder="Write Something"
              label="Other Requirements"
              error={errors?.requirements?.message}
              name="requirements"
            />
          </Container>
        </GroupItemStyled>
        <ButtonHidden type="submit" ref={ref} />
        {/* dynamic */}
      </Form>
    </FormProvider>
  );
});

const ChangeGroupNameModal = ({ isOpen, closeModal, value, onRename }) => {
  const [name, setName] = useState('');

  useEffect(() => {
    setName(value);
  }, [value]);

  const handleRename = () => {
    onRename(name);
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={() => {
        closeModal();
        setName('');
      }}
    >
      <div style={{ marginBottom: '14px' }}>
        <TextField onChange={(e) => setName(e.target.value)} value={name} placeholder="Enter your group" name="rename-group" />
      </div>
      <div>
        <Button label="Rename" onClick={handleRename} />
      </div>
    </Modal>
  );
};

export default KolsRequirement;

const Selectbox = styled<any>(OriginalSelectbox)`
  margin-bottom: 0px;
  flex: 1;
`;

const Datepicker = styled<any>(OriginalDatepicker)`
  margin-bottom: 0px;
  flex: 1;
`;

const TextField = styled<any>(OriginalTextField)`
  margin-bottom: 0px;
  flex: 1;
  width: auto;
  & p.label {
    width: 100%;
  }
  & input {
    background-color: #ffffff;
  }
  ${(props) =>
    !!props.width &&
    css`
      flex-basis: ${props.width};
      flex-grow: 0;
    `}

  ${mobileQueryString} {
    flex-basis: unset;
    width: 100%;
    margin-bottom: 10px;

    & input {
      flex-basis: 100%;
    }
  }
`;

const TextFieldTag = styled<any>(OriginalTextFieldTag)`
  margin-bottom: 0px;
  flex: 1;
`;

const TextFieldArea = styled<any>(OriginalTextFieldArea)`
  margin-bottom: 0px;
  flex: 1;
`;

const CheckboxList = styled<any>(OriginalCheckboxList)`
  margin-bottom: 0px;
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const RadioInput = styled<any>(OriginalRadioInput)`
  margin-bottom: 0px;
  flex: 1;
`;
const TextFieldCurrency = styled<any>(OriginalTextFieldCurrency)`
  margin-bottom: 0px;
  flex: 1;
`;
const TextFieldNumber = styled<any>(OriginalTextFieldNumber)`
  margin-bottom: 0px;
  flex: 1;
`;

const CampaignTitle = styled.h3`
  margin: 0;
  font: normal normal 500 16px/19px Helvetica Neue;
  letter-spacing: 0px;
  color: #5770c6;
`;

const Form = styled.form`
  max-width: 100%;
  width: 100%;
`;

const GroupItemStyled = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  --gap: ${(props) => props.gap || '20px'};
  & > * + * {
    margin-left: var(--gap);
  }
  margin-bottom: ${(props) => props.mb || '20px'};
  ${(props) =>
    !!props.itemWidth &&
    css`
      & > * {
        width: ${props.itemWidth};
      }
    `}
  ${(props) =>
    !!props.col &&
    css`
      flex-direction: column;
    `}
  ${(props) =>
    !!props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    !!props.wrap &&
    css`
      flex-wrap: wrap;
    `}
  ${(props) =>
    !!props.ml &&
    css`
      margin-left: ${props.ml};
    `}
  ${(props) =>
    !!props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `}
`;
const GroupHeaderStyled = styled<any>(GroupItemStyled)`
  display: flex;
  --gap: ${(props) => props.gap || '30px'};
  width: calc(100% + var(--gap));
  margin-left: calc(var(--gap) * -0.5);
  margin-right: calc(var(--gap) * -0.5);
  & > * + * {
    margin-left: var(--gap);
  }
  margin-bottom: ${(props) => props.mb || '20px'};

  ${mobileQueryString} {
    display: none;
  }
`;

const DeliverablesWrapperStyled = styled.div<any>`
  flex-wrap: wrap;
`;

const GroupFollowerStyled = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 700px;
  & > div {
    margin-bottom: 12px;
    margin-right: 19px;
    width: 158px;
    max-width: 158px;
    flex: none;
    input {
      flex: none;
      width: 100%;
    }
  }

  ${mobileQueryString} {
    width: 100%;
    justify-content: space-between;

    & > div {
      max-width: none;
      width: calc(50% - 10px);
      margin-right: 0;
    }
  }
`;

const TextDescription = styled.p<any>`
  color: #949494;
  font: normal normal normal 12px/14px Helvetica Neue;
  margin: 0;
  max-width: 700px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${mobileQueryString} {
    white-space: normal;
  }
`;

const TitleStyled = styled.p<any>`
  color: ${(props) => props.color || '#5770c6'};
  font: normal normal 500 16px/19px Helvetica Neue;
  font-size: 15px;
  margin: 0;
  margin-bottom: ${(props) => props.mb || '18px'};
  margin-top: ${(props) => props.mt || '0px'};
`;

const CashStyled = styled<any>(TextFieldCurrency)`
  max-width: 158px;
  width: 158px;
  flex-basis: 158px;
  input {
    width: 100%;
  }

  ${mobileQueryString} {
    flex-basis: unset;
    max-width: none;
    width: 100%;
    margin-bottom: 20px;
  }
`;

const PikValueLabelStyled = styled.div<any>`
  display: flex;
  justify-content: space-between;
`;

const TextFieldDeliverables = styled<any>(TextFieldNumber)`
  max-width: 158px;
  margin-bottom: 0px;
  & .textnumber {
    &__arrow {
      height: 100%;
      top: 0px;
      right: 0px;
      padding: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 1px solid #d9d9d9;
      width: 82px;
    }
    &__left,
    &__right {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      svg {
        width: 20.6px;
        fill: #3e3e3e;
      }
    }

    &__divider {
      min-width: 1px;
    }
  }
  & input[type='number'] {
    width: 100%;
    -moz-appearance: textfield;
  }
  // hidden default arrow of number input
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const SelectType = styled<any>(Selectbox)`
  max-width: 158px;
`;

const LineStyled = styled.div<any>`
  width: 100%;
  position: relative;
  height: 1px;
  display: block;
  margin-bottom: ${(props) => props.mb || '18px'};
  &::after {
    position: absolute;
    display: block;
    content: '';
    top: 0px;
    height: 1px;
    width: 90%;
    background: #eeeeee;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const AddMoreFieldStyled = styled.div<any>`
  color: #5770c6;
  font: normal normal 500 16px/19px Helvetica Neue;
  padding-left: 33px;
  margin-bottom: 23px;
  display: flex;
  justify-content: space-between;
  & > span {
    cursor: pointer;
  }
  padding-right: 0;
`;

const AddMoreGroup = styled.div<any>`
  color: #5770c6;
  font: normal normal 500 16px/19px Helvetica Neue;
  margin-bottom: 23px;
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 0;
  display: flex;
  justify-content: space-between;
  & > span {
    cursor: pointer;
  }
`;

const ActionStyled = styled.div<any>`
  width: 180px;
  flex-basis: 81px;
  display: flex;
  justify-content: flex-end;
  margin-left: 0px;
  & span {
    color: #5770c6;
    font: normal normal normal 14px/16px Helvetica Neue;
    cursor: pointer;
  }
  & svg {
    cursor: pointer;
  }
`;

const AddIcon = styled<any>(AddSVG)`
  cursor: pointer;
  display: block;
  margin-right: 19.78px;
  width: 17px;
  height: 17px;
`;

const CollapsibleTitle = styled.div<any>`
  display: flex;
  justify-content: space-between;
  padding: 23px 0px 16px 20px;
  border-bottom: 1px solid #eeeeee;
  cursor: pointer;
`;
const CollapsibleGroup = styled<any>(Collapsible)`
  & svg.Collapsible__arrow {
    transition: all 0.5s;
  }
  & .is-closed {
    color: #949494;
    font: normal normal normal 14px/16px Helvetica Neue;
    & svg.Collapsible__arrow {
      transform: rotate(-90deg);
    }
  }
  & .is-open {
    color: #0b0b0b;
    font: normal normal 500 14px/17px Helvetica Neue;
    & svg.Collapsible__arrow {
      transform: rotate(360deg);
    }
    & .Collapsible__title {
      border-bottom: none;
    }
  }
`;

const WrapperStyled = styled.div<any>`
  & .Collapsible__right_action {
    display: inline-flex;
    align-items: flex-end;
    padding-right: 20px;
  }
  & .Collapsible__rename {
    font: normal normal normal 14px/16px Helvetica Neue;
    color: #5770c6;
    margin-right: 10.63px;
  }
  & .Collapsible__remove {
    font: normal normal normal 14px/16px Helvetica Neue;
    color: #f44336;
    margin-right: 10.63px;
  }
  & .Collapsible__contentOuter {
    background: #fbfbfb;
    padding-left: 20px;
  }
  & .Collapsible__contentInner {
  }
  & .is-open {
    & .Collapsible__title {
      border-bottom: none;
      background: #fbfbfb;
      font: normal normal 500 14px/17px Helvetica Neue;
    }
    & .Collapsible__rename {
      font: normal normal normal 14px/16px Helvetica Neue;
      color: #5770c6;
    }
  }
  svg.Collapsible__arrow {
    transition: all 0.5s;
    width: 17px;
    margin-bottom: 4px;
  }

  ${mobileQueryString} {
    & .Collapsible__contentOuter {
      padding: 0 20px;
    }
  }
`;

const OpenIconStyled = styled<any>(PenSVG)`
  margin-right: 6px;
`;

const ButtonHidden = styled.button<any>`
  display: none;
`;
const ActionDelete = styled.span<any>`
  padding-right: 20px;
`;
const FormContainerStyled = styled.div<any>`
  max-width: 700px;
  width: 100%;
  display: flex;
`;

const HideInputId = styled.input<any>`
  display: none;
`;

const CustomErrorMessageStyled = styled<any>(ErrorMessageStyled)`
  padding: 0 20px;
`;

const DeliverableItem = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const Container = styled.div<any>`
  width: 100%;
  max-width: 700px;
  display: flex;
  align-items: flex-start;
  & > * + * {
    margin-left: 20px;
  }
  ${mobileQueryString} {
    flex-direction: column;
    & > * + * {
      margin-left: 0;
    }
  }
`;
const HideButtonStyled = styled.button<any>`
  display: none;
`;

const FlexBox = styled.div<any>`
  width: 100%;
  max-width: 700px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 20px;

  ${mobileQueryString} {
    display: grid;
    row-gap: 30px;
  }
`;
