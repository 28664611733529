// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentMetricsArea {
 background-color: #F4F4F4;
 padding: 20px;
}

.areaTitle {
    color: black;
    font-size: 16px;
    font-weight: bold;
  }
  
  .inputField {
    color: black;
    background-color: white;
    margin-top: 5px;
    border: 1px solid gray;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    width: calc(100vw / 5);
  }
  
  .contentRow {
    display: flex;
    margin-top: 15px;
  }

  .contentField {
    display: flex;
    margin-top: 15px;
  }`, "",{"version":3,"sources":["webpack://./src/components/campaigns/ContentMetrics/ContentMetricField/ContentMetricArea.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,aAAa;AACd;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,YAAY;IACZ,uBAAuB;IACvB,eAAe;IACf,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,gBAAgB;EAClB","sourcesContent":[".contentMetricsArea {\n background-color: #F4F4F4;\n padding: 20px;\n}\n\n.areaTitle {\n    color: black;\n    font-size: 16px;\n    font-weight: bold;\n  }\n  \n  .inputField {\n    color: black;\n    background-color: white;\n    margin-top: 5px;\n    border: 1px solid gray;\n    height: 40px;\n    padding-left: 10px;\n    padding-right: 10px;\n    border-radius: 10px;\n    width: calc(100vw / 5);\n  }\n  \n  .contentRow {\n    display: flex;\n    margin-top: 15px;\n  }\n\n  .contentField {\n    display: flex;\n    margin-top: 15px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
