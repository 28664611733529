// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  color: black;
  font-size: 16px;
}

.inputField {
  color: black;
  background-color: white;
  margin-top: 15px;
  border: 1px solid gray;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  width: calc(100vw / 5);
}

.contentRow {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/campaigns/ContentMetrics/ContentMetricField/ContentMetricsField.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,uBAAuB;EACvB,gBAAgB;EAChB,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".title {\n  color: black;\n  font-size: 16px;\n}\n\n.inputField {\n  color: black;\n  background-color: white;\n  margin-top: 15px;\n  border: 1px solid gray;\n  height: 40px;\n  padding-left: 10px;\n  padding-right: 10px;\n  border-radius: 10px;\n  width: calc(100vw / 5);\n}\n\n.contentRow {\n  display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
